<template>
  <div
    role="tablist"
    class="collapse-icon"
    :class="collapseClasses"
  >
    <slot />
  </div>
</template>

<script>

export default {
    props: {
        accordion: {
            type: Boolean,
            default: false,
        },
        hover: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'default',
        },
    },

    computed: {
      collapseClasses() {
        const classes = []

        // Collapse Type
        const collapseVariants = {
          default: 'collapse-default',
          border: 'collapse-border',
          shadow: 'collapse-shadow',
          margin: 'collapse-margin',
        }
        classes.push(collapseVariants[this.type])

        return classes
      },
    },

    data() {
      return {
        collapseID: '',
      }
    },
}
</script>
