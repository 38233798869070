<template>
    <div>
        <b-card class="mb-3">
            <div class="app-card-profile-title mb-1">ZZP gegevens</div>

            <app-wizard-kvk :applicationData.sync="itemData" />

            <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                <template v-slot:col-1>
                    <app-input label="Vul hier je BTW nummer in" id="vi-btw-number" additionalInfo="Het BTW ziet er zo uit: landcode NL, 9 cijfers, letter B, en een controlegetal van 2 cijfers" v-model="cItemData.btw_number" validatorName="BTW nummer" validatorRules="btw-number"  mask="BTW" :uppercase="true" /> 
                </template>
            </app-row-left-label>

            <app-row-left-label label="Bent u vrijgesteld van BTW?">
                <app-radio-group v-model="cItemData.is_btw_number" :options="option" validatorName="isBtw" validatorRules="required" />
            </app-row-left-label>

            <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                <template v-slot:col-1>
                    <app-input label="Registratienummer WTZA" v-model="cItemData.wtza_number" validatorName="Registratienummer WTZA" validatorRules="digits:8" type="integer_08" /> 
                </template>
            </app-row-left-label>

        </b-card>

        <app-collapse accordion type="margin" class="mb-3">

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="Identificatiebewijs" :id="'item9'">
                    <app-row-left-label class="mb-3" :slots="[6,6]">
                        <template v-slot:col-1>
                            <app-radio-group label="Selecteer het type identificatiebewijs dat je wilt uploaden" :options="document_type" v-model="cItemData.document_type" validatorName="DocumentType" validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }"/>
                            <small class="text-muted">Voeg alleen door de Nederlandse overheid uitgegeven identiteitsbewijzen toe</small><br/>
                        </template>
                    </app-row-left-label>

                    <people-applicants-profile-wizard-document-id-component :itemData="itemData" />
                </app-collapse-item>
                <validation-provider :rules="(itemData.document_id.status == 1 && itemData.document_type != null ) ? '' : 'required'" #default="{ errors }" name="Identificatiebewijs" :vid="'id_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="Bankgegevens" :id="'item8'">
                    <people-applicants-profile-wizard-bank-details-component :itemData="itemData" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_bank.status == 1 ? '' : 'required'" #default="{ errors }" name="Bankpas" :vid="'bank_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>


            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="Diploma('s)" :id="'item1'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="3" :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_diploma" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_diploma.status == 1 ? '' : 'required'" #default="{ errors }" name="Diploma('s)">
                    <small class="text-danger ml-1" v-if="itemData.document_diploma.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="Verklaring omtrent gedrag" :id="'item2'">
                    <app-radio-group v-model="cItemData.is_vog_document" :options="vog_option" />
                    <people-applicants-profile-wizard-document-component class="mt-4" v-if="cItemData.is_vog_document != null && cItemData.is_vog_document == 1" :id_document_const_type="9" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_verklaring" />
                </app-collapse-item>

                <validation-provider :rules="cItemData.is_vog_document != null ? '' : 'required'" v-if="cItemData.is_vog_document == null"  #default="{ errors }" :custom-messages="{ required: 'Is een verplicht veld' }">
                    <small class="text-danger ml-1"  v-if="cItemData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>

                <validation-provider :rules="(cItemData.document_verklaring.status == 1 || cItemData.is_vog_document == null || cItemData.is_vog_document != 1)  ? '' : 'required'" v-if="cItemData.is_vog_document != 0"   #default="{ errors }" name="Verklaring omtrent gedrag">
                    <small class="text-danger ml-1" v-if="cItemData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="Geschillencommissie" :id="'item4'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="5" :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_disputes" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_disputes.status == 1 ? '' : 'required'" #default="{ errors }" name="Geschillencommissie">
                    <small class="text-danger ml-1" v-if="itemData.document_disputes.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="Beroepsaansprakelijkheid" :id="'item5'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="6" :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_liability" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_liability.status == 1 ? '' : 'required'" #default="{ errors }" name="Beroepsaansprakelijkheid">
                    <small class="text-danger ml-1" v-if="itemData.document_liability.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="CV" :id="'item6'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="7" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_cv" accept_files="application/msword, application/pdf" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_cv.status == 1 ? '' : 'required'" #default="{ errors }" name="CV">
                    <small class="text-danger ml-1" v-if="itemData.document_cv.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>
            
            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="Referentie" :id="'item10'">
                    <people-applicants-profile-wizard-reference-component :itemData="itemData" />
                </app-collapse-item>
                <validation-provider #default="{ errors }" name="Referentie" :vid="'reference_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">

                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item3'">
                    <template v-slot:header>
                        <span class="lead collapse-title">Kwaliteitsregister</span>
                        <span class="text-muted ml-1"> (optioneel) </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="4" :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_quality" />
                </app-collapse-item>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je optionele documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item7'">
                    <template v-slot:header>
                        <span class="lead collapse-title">Certificaten</span>
                        <span class="text-muted ml-1"> (optioneel) </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="10" :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_certificate" />
                </app-collapse-item>
                <small class="text-danger ml-1"></small>
            </b-card>
        </app-collapse>
    </div>
</template>
<script>
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import { ValidationProvider } from "vee-validate";
import PeopleApplicantsProfileWizardDocumentComponent from './PeopleApplicantsProfileWizardDocumentComponent.vue';
import AppWizardKvk from '@core/components/Kvk/AppWizardKvk.vue';
import PeopleApplicantsProfileWizardBankDetailsComponent from './PeopleApplicantsProfileWizardBankDetailsComponent.vue';
import PeopleApplicantsProfileWizardDocumentIdComponent from './PeopleApplicantsProfileWizardDocumentIdComponent.vue';
import PeopleApplicantsProfileWizardReferenceComponent from './PeopleApplicantsProfileWizardReferenceComponent.vue';

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        ValidationProvider,
        PeopleApplicantsProfileWizardDocumentComponent,
        PeopleApplicantsProfileWizardDocumentIdComponent,
        AppWizardKvk,
        PeopleApplicantsProfileWizardBankDetailsComponent,
        PeopleApplicantsProfileWizardReferenceComponent
    },

    props: ["itemData", "substeps"],

    created() {

    },

    computed: {
        cItemData:{
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        }
    },
    
    data() {
        return {
            option: [
                { value: 1, text: "Ja" },
                { value: 0, text: "Nee" },
            ],
            vog_option: [
                { value: 1, text: "VOG toevoegen" },
                { value: 0, text: "VOG aanvragen" },
            ],

            document_type:[
                { value: 1, text: "Paspoort" },
                { value: 2, text: "Identiteitskaart" },
                { value: 3, text: "Verblijfsdocument" },
            ],
        };
    },

    methods: {
    }
    
};
</script>

<style>
[class*="collapse-"] .card .card-body{
    padding: 1.5rem;   
}
</style>
