<template>
    <b-row>
            <b-col cols="4" class="pr-5px">                
                <app-select v-model="cPrefix" :clearable="false" :selectOnTab="true" :options="internationals" :disable="disabled" @input="changePrefix"/>
            </b-col>
            <b-col cols="8" class="pl-5px">
                <app-input v-model="cNumber" validatorName="Telefoonnummer" :disabled="disabled" :validatorRules=" isRequired == true ? phoneRulesRequired : phoneRules " :mask="phoneMask"/>
            </b-col>
        </b-row> 
</template>
<script>

import internationals_json from "@/assets/json/internationals.json";

export default {
    
    props: {
            prefix: {
                type: String,
                default: "+31"
            },

            phone: {
                type: String
            },

            isRequired: { type: Boolean, default: true},
            disabled: {type: Boolean, default: false}
    },
  
    computed: {
        
        cPrefix: {
            get() { return this.prefix },
            set(value) {this.$emit('update:prefix', value) }
        },

        cNumber: {
            
            get() { return this.phone },
            set(value) {this.$emit('update:phone', value) }
        }    
    },

    data() {
        return {
            internationals: internationals_json,
            phoneMask: "##########",
            phoneRules: "min:9",
            phoneRulesRequired: "required|min:9"
        };
    },

    methods: {

          changePrefix(value) {

            if (value == '+31') {
                this.phoneMask = "##########";
                this.phoneRules = "min:9";
                this.phoneRulesRequired = "required|min:9";
            } else {
                this.phoneMask = "####################";
                this.phoneRules = "min:5";
                this.phoneRulesRequired = "required|min:5";
            }
        },
      
    }

};
</script>

<style>

</style>