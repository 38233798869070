<template>
    <div>
        
        <app-collapse accordion type="margin" class="mb-3">

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="Identificatiebewijs" :id="'item1'" >
                    <app-row-left-label class="mb-3" :slots="[6,6]">
                        <template v-slot:col-1>
                            <app-radio-group label="Selecteer het type identificatiebewijs dat je wilt uploaden" :options="document_type" v-model="citemData.document_type" validatorName="DocumentType" validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }"/>
                            <small class="text-muted">Voeg alleen door de Nederlandse overheid uitgegeven identiteitsbewijzen toe</small><br/>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6,6]" :isMarginBetween="false" class="mb-3">
                        <template v-slot:col-1>
                            <app-input label="Vul hier je BSN nummer in" v-model="citemData.bsn_number" validatorName="BSN nummer" validatorRules="required|sofi-number" v-mask="'#########'" />
                        </template>
                    </app-row-left-label>
                    
                    <people-applicants-profile-wizard-document-id-component :itemData="itemData" />
                </app-collapse-item>         
                <validation-provider :rules="(itemData.document_id.status == 1 && itemData.document_type != null && itemData.bsn_number != null && itemData.bsn_number != '') ? '' : 'required'" #default="{ errors }" name="Identificatiebewijs" :vid="'id_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="Bankgegevens" :id="'item6'">
                    <people-applicants-profile-wizard-bank-details-component :itemData="itemData" />
                </app-collapse-item>

                <validation-provider :rules="itemData.document_bank.status == 1 ? '' : 'required'" #default="{ errors }" name="Bankpas" :vid="'bank_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="Diploma('s)" :id="'item2'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="3"  :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_diploma" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_diploma.status == 1 ? '' : 'required'" #default="{ errors }" name="Diploma('s)">
                    <small class="text-danger ml-1" v-if="itemData.document_diploma.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="Verklaring omtrent gedrag" :id="'item3'">
                    <app-radio-group v-model="citemData.is_vog_document" :options="vog_option" />
                   <people-applicants-profile-wizard-document-component  v-if="citemData.is_vog_document != null && citemData.is_vog_document == 1" :id_document_const_type="9" class="mt-4" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_verklaring" />
                </app-collapse-item>

                <validation-provider :rules="citemData.is_vog_document != null ? '' : 'required'" v-if="citemData.is_vog_document == null"  #default="{ errors }" :custom-messages="{ required: 'Is een verplicht veld' }">
                    <small class="text-danger ml-1" v-if="citemData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>

                <validation-provider :rules="(citemData.document_verklaring.status == 1 || citemData.is_vog_document == null || citemData.is_vog_document != 1)  ? '' : 'required'" v-if="citemData.is_vog_document != 0"   #default="{ errors }" name="Verklaring omtrent gedrag">
                    <small class="text-danger ml-1" v-if="citemData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="CV" :id="'item4'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="7" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_cv" accept_files="application/msword, application/pdf" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_cv.status == 1 ? '' : 'required'" #default="{ errors }" name="CV">
                    <small class="text-danger ml-1" v-if="itemData.document_cv.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="Referentie" :id="'item7'">
                    <people-applicants-profile-wizard-reference-component :itemData="itemData" />
                </app-collapse-item>
                <validation-provider #default="{ errors }" name="Referentie" :vid="'reference_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>
            
            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je optionele documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item5'">
                    <template v-slot:header>
                        <span class="lead collapse-title">Certificaten</span>
                        <span class="text-muted ml-1"> (optioneel) </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="10" :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_certificate" />
                </app-collapse-item>
                <small class="text-danger ml-1"></small>
            </b-card>
        </app-collapse>

        <b-card class="mb-3">
            <div class="app-card-profile-title mb-3">
                Loonheffingskorting toepassen
                <img id="popover-target-1" tabindex="0" src="@/assets/images/icons/info-button.svg" height="18px" style="vertical-align: text-top; color: #b5d5f3" class="cursor-pointer" @click="showLoonInfo" />
            </div>
            <app-radio-group v-model="citemData.id_payroll_taxes" label="Geef hier hoe je gebruik wilt maken van de loonheffingskorting" :stacked="true" :options="payroll_taxes" validatorName="PayrollTaxes" validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }" />
        </b-card>

        <b-popover target="popover-target-1" triggers="focus" no-fade>
            <template #title>
                Loonheffinskorting toepassen
            </template>
            <p class="app-popover-text"><span class="font-weight-600">Ja, met loonheffingskorting</span> (regulier, er wordt loonheffing berekend en er wordt loonheffinskorting berekend).</p>
            <p class="app-popover-text"><span class="font-weight-600 app-popover-text">Ja, zonder loonheffingskorting</span> (het werk via HappyNurse is voor de flexwerker een tweede baan, er wordt al ergens anders loonheffingskorting berekend).</p>
            <p class="app-popover-text"><span class="font-weight-600 app-popover-text">Nee</span> (er wordt geen loonheffing berekend, de fiscus stelt dat er een optie moet zijn om geen loonheffing te berekenen).</p>
            <p class="app-popover-text"><span class="font-weight-600 app-popover-text">Anoniementarief</span> (Indien een werknemer zich voor aanvang van het dienstverband niet bekend wil (of kan) maken middels NAW en BSN gegevens, of zich niet wil identificeren aan de hand van een geldig identiteitsbewijs, is de werkgever verplicht deze te belasten met het toptarief van 52% volgens de WID (Wet op de Identificatieplicht)).</p>
        </b-popover>
    </div>
</template>
<script>
import { BPopover } from "bootstrap-vue";
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import PeopleApplicantsProfileWizardDocumentIdComponent from './PeopleApplicantsProfileWizardDocumentIdComponent.vue';
import PeopleApplicantsProfileWizardDocumentComponent from './PeopleApplicantsProfileWizardDocumentComponent.vue';
import PeopleApplicantsProfileWizardBankDetailsComponent from './PeopleApplicantsProfileWizardBankDetailsComponent.vue';
import PeopleApplicantsProfileWizardReferenceComponent from './PeopleApplicantsProfileWizardReferenceComponent.vue';

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        BPopover,
        PeopleApplicantsProfileWizardDocumentIdComponent,
        PeopleApplicantsProfileWizardDocumentComponent,
        PeopleApplicantsProfileWizardBankDetailsComponent,
        PeopleApplicantsProfileWizardReferenceComponent
        
    },

    props: ["itemData"],

    computed: {
        citemData:{
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        }
    },

    data() {
        return {
            document_type:[
                { value: 1, text: "Paspoort" },
                { value: 2, text: "Identiteitskaart" },
                { value: 3, text: "Verblijfsdocument" },
            ],
            payroll_taxes: [
                { value: 1, text: "Ja, met loonheffingskorting" },
                { value: 2, text: "Ja, zonder loonheffingskorting" },
                { value: 3, text: "Nee" },
                { value: 4, text: "Anoniementarief" },
            ],

            vog_option: [
                { value: 1, text: "VOG toevoegen" },
                { value: 0, text: "VOG aanvragen" },
            ],
        };
    },

    methods: {
        showLoonInfo() {
            this.$root.$emit("bv::show::popover", "popover-target-1");
        },
    },
};
</script>

<style>
.popover {
    max-width: 360px !important;
}

[class*="collapse-"] .card .card-body{
    padding: 1.5rem;   
}

</style>
