<template>
    <div>
        <app-row-left-label :slots="[6, 6]" :isMarginBetween="false" class="mt-2 mb-2">
            <template v-slot:col-1>
                <app-input v-model="cItemData.iban" :label="cIbanLabel"  additionalInfo="Deze kun je vinden op de voorzijde van je bankpas" validatorRules="required|eleven-proof" validatorName="IBAN" mask="lettersE3-32" />
            </template>
        </app-row-left-label>
        

        <div class="app-input-top-label app-local-margin">Voeg een foto van je bankpas toe</div>
        <people-applicants-profile-wizard-document-component :id_document_const_type="37" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_bank" />
    </div>
    
</template>
<script>

import PeopleApplicantsProfileWizardDocumentComponent from './PeopleApplicantsProfileWizardDocumentComponent.vue'

export default {

    components: {
        PeopleApplicantsProfileWizardDocumentComponent
    },
    
    props:{
        itemData: Object
    },

    created(){

    },
    
    computed: {
        cItemData:{
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        },

        cIbanLabel() {
            if(this.itemData.applicant_type == 1){//UZK
                return 'Vul hier je bankrekening nummer in';
            }else if(this.itemData.applicant_type == 2){//ZZP
                return 'Vul hier je zakelijke bankrekening nummer in';
            }else{
                return 'Vul hier je IBAN';
            }
        }
    },

    data(){
        return{

        }
    },

    methods: {

    }

}
</script>
<style scoped>
.app-local-margin{
    margin-bottom: 10px !important;
}
</style>