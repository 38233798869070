<template>
    <div>
        
        <h1 class="mt-2 mb-4">Persoonlijke <span>informatie</span></h1>

        <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
            <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">Succesvol opgeslagen</span>
            </div>
        </b-alert>

        <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
            <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">Unsuccesvol opgeslagen</span>
            </div>
        </b-alert>

        <b-overlay :show="loading" :opacity="0.0">

            <b-card class="mb-3">
                <div class="app-card-profile-title mb-3">Persoonlijke gegevens</div>
                    <b-row class="mb-3">
                        <b-col>
                             <app-radio-group label="Hoe wil je aangesproken worden?" v-model="cItemData.person_data.sex" :options="sex" validatorRules="required" validatorName="Sex" :validatorCustomMessage="{ required: 'Het veld is verplicht' }" />
                        </b-col>
                    </b-row>

                    <app-row-left-label :slots="[4,2,6]" class="mb-3">
                        <template v-slot:col-1>
                            <app-input label="Voornaam" v-model="cItemData.person_data.first_name" validatorRules="required" validatorName="Voornaam" mask="letters-20" />
                        </template>

                        <template v-slot:col-2>
                            <app-input label="Tussenvoegsel" v-model="cItemData.person_data.inbetween_name" mask="lettersE1-10" />
                        </template>

                        <template v-slot:col-3>
                            <app-input label="Achternaam" v-model="cItemData.person_data.last_name" validatorRules="required" validatorName="Achternaam" mask="lettersE1-30" />
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[4,8]" class="mb-3">
                        <template v-slot:col-1>
                            <app-date label="Vul hier je geboortedatum in" v-model="cItemData.person_data.birth_date" validatorRules="required|date-check|age-check" validatorName="Geboortedatum" />
                        
                        </template>
                        
                        <template v-slot:col-2>
                            <app-input label="Vul hier je geboorteplaats in" v-model="cItemData.person_data.birth_place" validatorRules="required" validatorName="Geboorteplaats" mask="lettersE2-25" />
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6,6]" class="mb-3">
                        <template v-slot:col-1>
                            <app-email :email.sync="cItemData.person_data.email" label="Vul hier je e-mailadres in" validatorRules="required" customSpinnerClass="app-local-input-spinner"/>
                        </template>

                        <template v-slot:col-2>
                            <label class="flabel mb-0"> Vul hier je telefoonnummer in </label>
                             <app-phone :prefix.sync="cItemData.person_data.phone_prefix" :phone.sync="cItemData.person_data.phone_number" :isRequired="true" />
                        </template>  
                    </app-row-left-label>
            </b-card>

            <b-card class="mb-3">
                <div class="app-card-profile-title mb-3">Adresgegevens</div>
                <app-wizard-address :address.sync="cItemData.address" />
            </b-card>


            <b-card class="mb-3" v-if="cItemData.person_data.big_required == 1">
                <div class="app-card-profile-title mb-3">BIG-geregistreerd</div>
                <app-row-left-label :slots="[6,6]">
                    <template v-slot:col-1>
                        <div class="flabel">Ben je BIG-geregistreerd voor je beroep?</div>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group :options="big" v-model="cItemData.big.is_big_number" validatorRules="required" validatorName="Big"/>
                    </template>
                </app-row-left-label>
                <app-wizard-big v-if="cItemData.big.is_big_number" class="mt-3 mb-3" :bigData.sync="cItemData.big" />
            </b-card>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

                
    </div>
</template>

<script>
import AppEmail from '@core/components/AppEmail.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppWizardAddress from '@core/components/Address/AppWizardAddress.vue';
import AppWizardBig from '@core/components/Big/AppWizardBig.vue';
import AppRadioGroup from '@core/components/AppRadioGroup.vue';
import { elevenproof,  date} from "@validations";

export default {
    components: {
        AppEmail,
        AppPhone,
        AppWizardAddress,
        AppWizardBig,
        AppRadioGroup
    },

    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading"],

    created(){
        this.loadAddress()
    },
    
    watch:{
        showSuccessAlert(){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        }
    },

    computed: {
         cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        },

        cIbanLabel() {
            if(this.itemData.person_data.applicant_type == 1){//UZK
                return 'Vul hier je bankrekening nummer in';
            }else if(this.itemData.person_data.applicant_type == 2){//ZZP
                return 'Vul hier je zakelijke bankrekening nummer in';
            }else{
                return 'Vul hier je IBAN';
            }
        }
    },

    data(){
        return{
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            sex: [
                { value: 1, text: "Man" },
                { value: 2, text: "Vrouw" },
            ],
            big: [
                { value: 1, text: "Ja" },
                { value: 0, text: "Nee" },
            ],
            address: {
                post_code: null,
                city: null,
                house_number: null,
                house_number_additional: null,
                street: null,
                latitude: null,
                longitude: null,
                type: null,
                country: null
            }
        }
    },
    
    methods:{
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        },

        loadAddress(){
                this.address.post_code = this.cItemData.post_code;
                this.address.city = this.cItemData.city,
                this.address.house_number = this.cItemData.house_number,
                this.address.house_number_additional = this.cItemData.house_number_additional,
                this.address.street = this.cItemData.street,
                this.address.latitude = this.cItemData.latitude,
                this.address.longitude = this.cItemData.longitude,
                this.address.type = this.cItemData.address_type,
                this.address.country = {name: this.cItemData.country_name, value: this.cItemData.id_country_data_main};
        }
    }
};
</script>

<style></style>
<style lang="scss">
</style>
