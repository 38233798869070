<template>
    <div>
        
        <h1 class="mt-2 mb-4">Risicovolle handelingen</h1>

        <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
            <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">Succesvol opgeslagen</span>
            </div>
            
        </b-alert>

        <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
            <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">Unsuccesvol opgeslagen</span>
            </div>
            
        </b-alert>

        <b-overlay :show="loading" :opacity="0.0">

            <b-card class="mb-3" v-if="cItemData.competences != null">
                
                <b-row v-if="array_functions.includes(cItemData.id_function_data_main) && !loading_competences">
                    <b-col class="mb-1">
                        <div class="flabel">Selecteer je gediplomeerde voorbehouden en risicovolle handelingen</div>
                        <div class="filters">
                            <b-button class="app-local-btn" :variant="selected_all ? 'third' : 'outline-third'" v-on:click="selectAll()" id="all">
                                Selecteer alles
                            </b-button>
                            <b-button class="app-local-btn" v-for="item in cItemData.competences" :key="item.id_competence_const_group" :variant="item.selected ? 'third' : 'outline-third'" v-on:click="selectOne(item)">
                                {{item.competence_group_name}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

                <b-row v-if="!array_functions.includes(cItemData.id_function_data_main)">
                    <b-col class="mb-1">
                        <div class="flabel">Voor de functie als {{lowerCase(cItemData.function_name)}} hoef je geen risicovolle handelingen op te geven</div>
                    </b-col>
                </b-row>
            </b-card>

            <div v-for="item in cItemData.competences" :key="item.id_competence_const_group">
                <b-card class="mb-3" v-if="item.selected == 1">
                    <app-row-left-label :slots="[6,6]">
                        <template v-slot:col-1>
                            <label class="app-input-top-label">{{item.competence_group_name}}</label>
                        </template>
                        <template v-slot:col-2>
                            <label class="app-input-top-label">Vul hier je verloopdatum in</label>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6,6]" v-for="competence in item.competences" :key="competence.id_competence_const_type">
                        <template v-slot:col-1>
                            <app-check-box :id="'checkbox_' + competence.id_competence_const_type.toString()" v-model="competence.selected" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="competence.competence_name" v-on:change="changeSelected(competence)" :minHeight="true" />
                        </template>
                        <template v-slot:col-2>
                            <app-date v-model="competence.competence_date" :validatorRules="competence.selected == '1' ? 'required|date-check' : '' " :validatorName="competence.competence_name" v-if="competence.selected == '1' " :validatorCustomMessage="{ required: 'Datum is verplicht' }" :disabledDatesPass="1" />
                        </template>
                    </app-row-left-label>

                </b-card>
            </div>

            <b-card class="mb-3" v-if="cItemData.competences != null">
                <app-row-left-label>
                    <app-check-box id="condition_privacy" v-model="cItemData.condition_privacy" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" validatorRules="required" :validatorCustomMessage="{ required: 'Conditie privacy is verplicht' }" >
                        <template v-slot:custom-right-label>
                            Ik heb de <b-link class="font-weight-600" href="#" @click="openPrivacy">privacyverklaring</b-link> van HappyNurse gelezen en begrepen
                        </template>  
                    </app-check-box>
                </app-row-left-label>

                <app-row-left-label>
                    <app-check-box id="condition_confirmation" v-model="cItemData.condition_confirmation" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="'Jouw gegevens worden gebruikt voor arbeidsbemiddeling, dit vindt deels geautomatiseerd plaats.'" validatorRules="required" :validatorCustomMessage="{ required: 'Conditiebevestiging is verplicht' }" />
                </app-row-left-label>
            </b-card>
            
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
         
    </div>
</template>

<script>
import axios from "@axios";

export default {
    components: {
    },

    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading"],

    watch:{
        showSuccessAlert(val){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        }
    },

    computed:{
       cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        },
    },

    data() {
        return {
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            loading_competences: false,
            competences_items: null,
            selected_all: this.itemData.selected_all_competences,
            array_functions: [8, 9, 10, 11]
        }
    },

    methods: {
        
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        },

        changeFunction(){
            
            this.loading_competences = true;
            
            axios
                .post("people/data-wizard/getCompetenceList/" + this.$route.params.token, {
                    id_function_data_main: this.cItemData.function.value})
                .then((res) => {              
                    this.cItemData.competences = res.data;
                })
                .catch((error) => {
                   console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_competences = false;
                });  

        },

        selectAll(){
            
            if(this.selected_all == 0){
                this.selected_all = 1;
                Object.keys(this.cItemData.competences).forEach((item) => {
                    this.cItemData.competences[item].selected = 1;
                });
                
            }else{
                this.selected_all = 0;
                Object.keys(this.cItemData.competences).forEach((item) => {
                    this.cItemData.competences[item].selected = 0;

                    this.cItemData.competences[item].competences.forEach(competence => {
                        competence.selected = 0;
                        competence.competence_date = null;
                    });

                });

            }
        },

        selectOne(item){
            if(item.selected == 1){
                item.selected = 0;
                this.selected_all = 0;

                item.competences.forEach(competence => {
                    competence.selected = 0;
                    competence.competence_date = null;
                });

            }else{
                item.selected = 1;
            }
            
        },

        changeSelected(item){
            if(item.selected == '0'){
                item.competence_date = null;
            }
                
        },

        openPrivacy() {
            window.open("https://www.happynurse.nl", "_blank").focus();
        },

        lowerCase(text){
            return text.toLowerCase();
        }
    },


};
</script>
<style>
.b-selected {
    filter: brightness(0) invert(1);
}

.app-local-btn{
    margin-top: 10px;
}
</style>
