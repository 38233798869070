<template>
    <div>
        <b-form @submit.prevent>
            <b-row v-if="id_document_const_type == 3">
                <b-col cols="12">
                    <small class="text-muted"> In het diplomaregister van DUO staan diplomagegevens van de meeste, door het ministerie van OCW erkende Nederlandse opleidingen. Download een gratis digitaal uittreksel. Het downloaden van een digitaal uittrekstel kan alleen door jouzelf gedaan worden, dus niet door de werkgever. </small>
                </b-col>
            </b-row>
            <b-row class="pt-1 pb-1" v-if="id_document_const_type == 3">
                <b-col cols="12">
                    <small class="text-muted"> Klik </small>
                    <small class="font-weight-bolder"><b-link href="#" @click="openModelInfo">hier</b-link></small>
                    <small class="text-muted"> om naar het diplomaregister te gaan.</small>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-row v-for="item_file in document.files" :key="item_file.id_document_data_main">
                        <b-col md="8" xl="8">
                            <span>{{ item_file.file_name_user }}</span>
                            <span v-if="id_document_const_type == 5 || id_document_const_type == 6" id="tooltip-expire-date"> | {{item_file.date_to}}</span>
                            <span v-else-if="id_document_const_type == 4 || id_document_const_type == 9" id="tooltip-creation-date"> | {{item_file.date_from}}</span>
                            <!--<b-tooltip target="tooltip-expire-date" triggers="hover">
                               Expire date
                            </b-tooltip>
                            <b-tooltip target="tooltip-creation-date" triggers="hover">
                                Creation date
                            </b-tooltip>-->
                        </b-col>
                        <b-col md="2" xl="2">
                            <span class="text-right font-weight-bolder"> {{ item_file.size }} MB </span>
                        </b-col>
                        <b-col md="2" xl="2">
                            <b-link href="#" @click="deleteFile(item_file.id_document_data_main)">
                                <span class="text-right" v-if="fDeleting === 0 || (fDeleting != 0 && fDeleting !== item_file.id_document_data_main)">verwijder</span>
                                <b-spinner class="ml-2" small v-if="fDeleting === item_file.id_document_data_main" />
                            </b-link>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row v-bind:class="{ 'mt-2': document.status === 1 }">
                <b-col v-if="(document.files == null || !(document.files.length >= this.maxFilesNumber))">  
                    <div class="image-upload-wrap">
                        <input class="file-upload-input" type="file" :accept="this.id_document_const_type == 7 ? accept_files_diploma : accept_files" v-on:change="addFile" />
                        <div class="drag-text">
                            <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!fUploading" />
                            <b-spinner v-if="fUploading" style="margin-top: 4px; margin-bottom:6px" />
                            <div>
                                <small class="text-muted" v-if="this.id_document_const_type != 7">Sleep of selecteer je documenten om deze te uploaden (JPG, PNG, PDF)</small>
                                <small class="text-muted" v-if="this.id_document_const_type == 7">Sleep of selecteer je documenten om deze te uploaden (PDF, DOC, DOCX)</small>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-form>
        <b-modal ref="my-modal" centered okVariant="primary" okTitle="DUO" cancelVariant="primary" cancelTitle="DIPLOMAHULP" title="Diplomaregister" size="lg" @ok="handleOk" @cancel="handleCancel">
            <div class="d-block">
                Het downloaden van een digitaal uittrekstel kan alleen door jouzelf gedaan worden, dus niet door de werkgever. Het inloggen bij DUO gaat via DigiD.
            </div>

            <div class="d-block font-weight-bolder pt-1">
                Diploma in het register
            </div>

            <div class="d-block">
                Er zijn twee manieren om het digitale uittreksel te downloaden.
                <ol>
                    <li>Vul de <span class="font-weight-bolder">diplomahulp</span> in om te zien of je diploma in het register staat. Volg de instructies in de diplomahulp, log in met DigiD om je uittreksel te downloaden.</li>
                    <li>Ga rechtstreeks naar de website van DUO, sla de diplomahulp over en log in met je DigiD om je uittreksel te downloaden.</li>
                </ol>
            </div>
        </b-modal>

        <b-modal ref="datepicker-modal" centered okVariant="primary" okTitle="OK" :title="datepickerModalTitle" size="sm" ok-only @ok="handleDatepickerOk">
            <b-row>
                <b-col>
                    <datepicker v-model="selectedDate" :inline="true" :language="languages[language]" :disabled-dates="disabledDates"></datepicker>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import axios from "@axios";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";
export default {
    components: {
        Datepicker,
    },

    props: {
                itemData: Object, 
                document: Object, 
                id_document_const_type: Number, 
                maxFilesNumber: Number,
                accept_files: {
                    default: "image/png, image/jpeg, image/jpg, application/pdf",
                    type: String
                },
                accept_files_diploma: {
                    default: "application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf",
                    type: String
                }
            },

    computed:{
        citemData: {
            get() { return this.itemData },
            set() { this.$emit('update:itemData', false) }, 
        },
    },
    
    data() {
        return {
            formData: new FormData(),
            datepickerModalTitle: "",
            fUploading: false,
            fDeleting: 0,
            selectedDate: "",
            language: "nl",
            languages: lang,
            disabledDates: {
                to: moment()
                    .subtract(1, "days")
                    .toDate(),
            },
        };
    },

    methods: {
        openModelInfo() {
            this.$refs["my-modal"].show();
        },
        openDatepickerModal(){
            this.$refs["datepicker-modal"].show();
        },
        handleOk() {
            window.open("https://www.duo.nl/particulier/inloggen-op-mijn-duo.jsp", "_blank").focus();
        },
        handleCancel() {
            window.open("https://www.duo.nl/particulier/uittreksel-diplomagegevens-downloaden.jsp", "_blank").focus();
        },
        handleDatepickerOk(){
       
            if(this.id_document_const_type == 5 || this.id_document_const_type == 6){
                if(moment(this.selectedDate).format("yyyy-MM-DD") == "Invalid date"){
                    this.formData.append("date_to", moment().format("yyyy-MM-DD"));
                }else{
                    this.formData.append("date_to", moment(this.selectedDate).format("yyyy-MM-DD"));
                }

            }else if(this.id_document_const_type == 4 || this.id_document_const_type == 9){
                if(moment(this.selectedDate).format("yyyy-MM-DD") == "Invalid date"){
                    this.formData.append("date_from", moment().format("yyyy-MM-DD"));
                }else{
                    this.formData.append("date_from", moment(this.selectedDate).format("yyyy-MM-DD"));
                }
            }

            this.sendFile();
        },
        addFile(e) {

            var file = e.target.files[0];

            if(this.id_document_const_type != 7 && !file.type.match('image/jp.*') && !file.type.match('image/png.*') && !file.type.match('application/pdf.*')) {
                alert("Alleen jpg, png en pdf bestanden worden geaccepteerd");
                file = "";
                return;
            }else if(this.id_document_const_type == 7 && !file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document.*') && !file.type.match('application/msword.*') && !file.type.match('application/pdf.*')) {
                alert("Alleen doc, docx en pdf bestanden worden geaccepteerd");
                file = "";
                return;
            }
            this.formData.append("id_person_data_main", this.itemData.id_person_data_main);
            this.formData.append("file", file);
            this.formData.append("file_name", file.name);
            this.formData.append("id_document_const_type", this.id_document_const_type);
            this.formData.append("file_size", file.size);
            
            if(this.id_document_const_type == 5 || this.id_document_const_type == 6){
                this.disabledDates = {to: moment().subtract(1, "days").toDate()}
                this.datepickerModalTitle = "Stel de vervaldatum in";
                this.openDatepickerModal();
            }else if(this.id_document_const_type == 4){
                this.disabledDates = {from: moment().subtract(0, "days").toDate()}
                this.datepickerModalTitle = "Stel de aanmaakdatum in";
                this.openDatepickerModal();
            }else if(this.id_document_const_type == 9){
                this.disabledDates = {to: moment().subtract(90, "days").toDate(), from: moment().subtract(0, "days").toDate()}
                this.datepickerModalTitle = "Stel de aanmaakdatum in";
                this.openDatepickerModal();
            }else{
                this.sendFile();
            }

        },
        sendFile(){
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
     
            this.fUploading = true;

            axios
                .post("people/data-wizard/addFile/" + this.$route.params.token, this.formData, config)
                .then((response) => {
                    this.formData = new FormData();
                    this.parseResponse(response);
                    this.fUploading = false;
                })
                .catch(function(error) {
                    this.fUploading = false;

                    console.log(error);
                });

        },
        deleteFile: function(id_document_data_main) {
            this.fDeleting = id_document_data_main;

            let formData = new FormData();
            formData.append("id_person_data_main", this.itemData.id_person_data_main);
            formData.append("id_document_data_main", id_document_data_main);
            formData.append("id_document_const_type", this.id_document_const_type);

            axios
                .post("people/data-wizard/deleteFile/" + this.$route.params.token, formData)
                .then((response) => {
                    this.parseResponse(response);
                    this.fDeleting = 0;
                })
                .catch((error) => {
                    this.fDeleting = 0;
                    console.error(`error during deleting files: ${error}`);
                });
        },

        parseResponse: function(response) {
            var nDocumentObject = this.document;
            if (response.data != undefined && response.data != null) {
                nDocumentObject.files = response.data.files;
                nDocumentObject.status = response.data.status;
            }
        },

        setFormatDate(dateValue) {
           return moment(dateValue).format('dd-MM-yyyy');
        },
    },
};
</script>

<style scoped>
.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    border: 1px dashed #b5d8f3;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}
.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.fileContainer {
    font-size: 12px;
    border: none;
    padding: 0 0 10px 0;
}
.fileContainer:hover {
    background-color: transparent;
}
.deleteButton {
    color: #71e6ee;
    cursor: pointer;
    float: left;
}
.files {
    width: 43%;
}
.loading {
    margin-left: 40%;
}
.uploadIcon {
    width: 13%;
}
</style>
