<template>
    <div>
                
        <app-row-left-label :slots="[4,2,6]" class="mb-3">
            <template v-slot:col-1>
                <app-input label="Voornaam" v-model="cItemData.person_reference.first_name" validatorRules="required" validatorName="Voornaam"  mask="letters-20" />
            </template>

            <template v-slot:col-2>
                <app-input v-model="cItemData.person_reference.inbetween_name" label="Tussenvoegsel" mask="lettersE1-10" />
            </template>

            <template v-slot:col-3>
                <app-input v-model="cItemData.person_reference.last_name" label="Achternaam" validatorRules="required" validatorName="Achternaam" mask="lettersE1-30" />
            </template>
        </app-row-left-label>

        <app-row-left-label :slots="[6,6]" class="mb-3">
            <template v-slot:col-1>
                <app-input v-model="cItemData.person_reference.function" label="Functie" validatorRules="required" validatorName="Functie" />
            </template>

            <template v-slot:col-2>
                <app-input v-model="cItemData.person_reference.organisation" label="Organisatie" validatorRules="required" validatorName="Organisatie" />
            </template>
        </app-row-left-label>

        <app-row-left-label :slots="[6,6]" class="mb-3">
            <template v-slot:col-1>
                <app-email :email.sync="cItemData.person_reference.email"  label="Vul hier je e-mailadres in" validatorRules="required" validatorName="Email" customSpinnerClass="app-local-input-spinner"/>
            </template>

            <template v-slot:col-2>
                <label class="flabel mb-0"> Vul hier je telefoonnummer in </label>
                    <app-phone :phone.sync="cItemData.person_reference.phone_number" :prefix.sync="cItemData.person_reference.phone_prefix" :isRequired="true" />
            </template>  
        </app-row-left-label>

    </div>
    
</template>
<script>

import AppEmail from '@core/components/AppEmail.vue';
import AppPhone from '@core/components/AppPhone.vue';
export default {

    props: ["itemData"],

    components: {
        AppEmail,
        AppPhone
    },

    created() {
        this.cItemData.person_reference.phone_prefix =  this.cItemData.person_reference.phone_prefix != null ? this.cItemData.person_reference.phone_prefix : "+31";
    },

    computed: {
        cItemData:{
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        },
        
    },

    data(){
        return{

        }
    },

    methods: {

    }
    
}
</script>
<style scoped>

</style>