<template>
    <div>
        <ValidationObserver ref="observer">
            <app-row-left-label :slots="[6, 6]" :isMarginBetween="true">
                <template v-slot:col-1>
                    <app-input id="vi-post-code" v-model="cAddress.post_code" :placeholder="'Postcode'" label="Postcode" validatorName="Postcode" validatorRules="required" mask="lettersE3-8" v-on:blur="checkAddress()" :uppercase="true"/>
                </template>
                <template v-slot:col-2>
                    
                    <app-row-left-label :slots="[6, 6]" :isMarginBetween="true">
                        <template v-slot:col-1>
                            <app-input id="vi-house-number" v-model="cAddress.house_number" :placeholder="'Huisnummer'" label="Huisnummer" validatorName="Huisnummer" validatorRules="required" v-on:blur="checkAddress()" type="integer_05" />
                        </template>
                        <template v-slot:col-2>
                            <app-input id="vi-number-additional" v-model="cAddress.house_number_additional" :placeholder="'Toevoeging'" label="Toevoeging" v-on:blur="checkAddress()" mask="lettersE3-5"/>
                        </template>
                    </app-row-left-label>

                </template>
            </app-row-left-label>

            <app-row-left-label :slots="[6, 6]" :isMarginBetween="true">
                <template v-slot:col-1>
                    <app-input label="Straat" v-model="cAddress.street" placeholder="Straat" validatorName="Straat" :validatorRules="cAddressRequired" mask="lettersE2-30" />
                </template>
                <template v-slot:col-2>
                    <app-input label="Woonplaats" v-model="cAddress.city" placeholder="Woonplaats" validatorName="Woonplaats" :validatorRules="cAddressRequired" mask="lettersE2-25" />
                </template>
            </app-row-left-label>

            <app-row-left-label :slots="[6, 6]" :isMarginBetween="true">
                <template v-slot:col-1>
                    <app-select label="Land" v-model="cAddress.country" type="getCountries" :disable="false" validatorName="Land" :validatorRules="cAddressRequired"/>
                </template>
            </app-row-left-label>

        </ValidationObserver>
    </div>
</template>

<script>

import {ValidationObserver} from "vee-validate";
export default {
    components: {
         ValidationObserver
    },

    props: {
        address: {},
        required: { type: Boolean, default: false }
    },

    created() {
        if(this.address.post_code.length != 0 || this.address.house_number.length != 0) {
            if (this.address.house_number_additional != null) {                
                this.checked_address_key = this.address.post_code + this.address.house_number + this.address.house_number_additional;
            } else {
                this.checked_address_key = this.address.post_code + this.address.house_number;
            }
        }         
    },

    computed: {
        cAddress:{
            get() { return this.address },
            set(value) { this.$emit('update:address', value) }
        },

        cErrorMessage:{
            get() { 
                    if (this.address.post_code.length == 0 && this.address.house_number.length == 0) return "Adres is en verplicht"
                    else if (this.address.post_code.length == 0) return "Postcode is enn verplicht veld"
                    else if (this.address.house_number.length == 0) return "Huisnummer is enn verplicht veld"
                    return ""
                }
        },

        cAddressRequired: {
            get() { 
                
                if(this.required) return "required";
                else if(this.address.post_code.length > 0 || this.address.house_number.length > 0 || (this.address.house_number_additional !== null && this.address.house_number_additional.length > 0) || this.address.city.length > 0 || this.address.street.length > 0) return "required";
                return null;
            }
        }
    },

    data() {
        return {
            checked_address_key : ""           
        };
    },

    methods: {
        checkAddress() {
            
            if(this.address.post_code.length == 0 || this.address.house_number.length == 0) {
                this.checked_address_key = "";
                this.$refs.observer.validate();
                return;
            }

            if (this.address.house_number_additional != null) {
                
                this.checked_address_key = this.address.post_code + this.address.house_number + this.address.house_number_additional;
            } else {
                this.checked_address_key = this.address.post_code + this.address.house_number;
            }
            
            this.$refs.observer.validate();            
        },
    },

};

</script>
